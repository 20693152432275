import React from "react"
import PropTypes from "prop-types"
import { Box, Button, Heading, Text, Stack } from "grommet"
import { Help } from "grommet-icons"
import ImageSharpData from "./imageHandling/imageSharpData"
import ImageSharpImage from "./imageHandling/imageSharpImage"
import styled from "styled-components"
import Link from "../components/link"

const ImageSharpImageStyledOpacity = styled(ImageSharpImage)`
    &::before,
    &::after {
      filter: opacity(
        ${({ opacity }) => {
  return opacity
}}
      );
    }
    `
// DET FIXME - need to adjust the justify property on text and the size based on rendered screen size

const StoryCardStack = (props) => {
  if (props.debugConsole) {
    console.log('Story card stack image identifier = ' + props.imageIdentifier)
  }
  const imageSharp = ImageSharpData(props)
  if (!imageSharp || imageSharp.length === 0) {
    console.log("Unable to locate image in background section")
    return null
  }
  if (props.debugConsole) {
    console.log("Image sharp in story card: " + JSON.stringify(imageSharp, null, 2))
    console.log("Image sharp length = " + imageSharp.length)
    console.log('Opacity = ' + props.opacity + ', and darken = ' + props.darken)
  }

  const imageData = imageSharp[0].node.childImageSharp.fluid

  const checkClick = (data) => {
    console.log('The button click data = ' + data)
  }

  const renderInteractiveOverlay = (href) => {
    return (href ?
        (<Box
          // the cross axis is horizonta here so align is left to right
          // this controls where the button goes IF container wrapping story card is not set to fill
          // stretch will literally stretch it
          //
          /*
           * As a complete overlay on stack, you have to use fill if you want to move vertically
           * Then you can use justify to move to bottom vertically
           * Use align = end and you can do alignContent too in case there are other elems
           * Put margin in the button control itself to get better results than a pad in box
           * Beware that on a separate layer it could overrun text so check and protect
           * bottom of second (text) layer
           */
          fill
          justify={"end"}
          responsive={true}
          // align moves horizontally as used in the stack
          align={"end"}
          // just to be sure add this
          alignContent={"end"}
        >
          <Button
            margin={{right: "small", bottom: "small"}}
            primary={true}
            color={props.buttonColor}
            size={"small"}
            // icons just cause problems on mobile unless/until we size dynamically
            //icon={<Help size={"medium"}></Help>}
            fill={false}
            // the align here is vertical within the box
            //align={"start"}
            onClick={checkClick}
            // inside a carousel, the stack will not actually see the button
            href={props.buttonHref}
            label={
              <Text weight={"bold"} margin={"xsmall"}>
                {props.cardButtonMessage || "Learn More"}
              </Text>
            }
          >
          </Button>
        </Box>) :
        undefined
    )
  }
  /*
   *  setting height of outer box does change image but also limits re-sizing
   *
   * Setting column responsive will limit horiz min by keeping button on left
   *
   * Setting a margin on the box surrounding button prevents it disappearing
   * Make sure the button goes in actual bottom left of image, since we use
   * the relative coordinates to simulate a button click
   */
  console.log('Stack image identifier = ' + props.imageIdentifier)

  // adjust level of header based on word count
  const cardTagLineWordCount = props.cardTagLine.split(' ').length;
  console.log('Tagline word cont = ' + cardTagLineWordCount);
  const cardTagLineHeaderSize = (cardTagLineWordCount <= 5) ? 1 : (cardTagLineWordCount < 10) ? 2 : 3;
  return (
    <Stack
      // use this to decide how layers above main get moved around
//      anchor={"bottom-right"}
      guidingChild={"first"}
      interactiveChild={"last"}
      margin={{bottom: "large"}}
    >
      <Box
        // THIS IS THE FIRST LAYER OF THE STACK
        fill
      >
        <ImageSharpImage
          imageIdentifier={props.imageIdentifier}
          debugConsole={props.debugConsole}
        />
      </Box>
      <Box
        // this will adjust horizontal IF anchor not set in stack
        direction={"row-responsive"}
        align={props.cardTaglineHorizontalAlign ? props.cardTaglineHorizontalAlign : "center"}
        // this will adjust vertical IF anchor not set in stack AND fill set in parent box
        fill
        // using justify between will put header at top and text towards bottom
        // best for mobile.  stretch works too but tends to put text in center of pic
        justify={props.cardTaglineVerticalAlign ? props.cardTaglineVerticalAlign: "between"}
        direction={"column"}
//          fill
      >
        <Heading
          color={props.taglineTextColor ? props.taglineTextColor : props.textColor}
          margin={{
            vertical: props.cardTaglineMarginTop,
            left: "small",
          }}
          responsive={true}
          level={props.cardTaglineHeadingLevel ? props.cardTaglineHeadingLevel: cardTagLineHeaderSize}
          weight={"bold"}
          // no default for this textalign- fall back to heading default which is start
          textAlign={props.cardTaglineTextAlign}
          truncate={false}
        >
          <div>
            {props.cardTagLine}
            <br/>
            {props.cardTaglineSecond}
          </div>

        </Heading>
        <Text
          // make sure you keep a margin here to ensure it stays away from bottom and side
          color={props.actionTextColor ? props.actionTextColor : props.textColor}
          margin={{bottom: "large", horizontal: "small"}}
          weight={"bold"}
          wordBreak={"normal"}
        >
          {props.cardCallToAttention}
        </Text>
    </Box>
      {renderInteractiveOverlay(props.buttonHref)}
    </Stack>
  )
}

const StyledStoryCardStack = styled(StoryCardStack)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`

StoryCardStack.propTypes = {
  imageIdentifier: PropTypes.string,
  imageName: PropTypes.string,
  cardTagLine: PropTypes.string,
  cardTaglineSecond: PropTypes.string,
  cardCallToAttention: PropTypes.string,
  cardButtonMessage: PropTypes.string,
  cardTaglineHorizontalAlign: PropTypes.string,
  cardTaglineVerticalAlign: PropTypes.string,
  cardTaglineHeadingLevel: PropTypes.number,
  cardTaglineTextAlign: PropTypes.string,
  cardTaglineMarginTop: PropTypes.string,
  textColor: PropTypes.string,
  buttonColor: PropTypes.string,
  catalog: PropTypes.string,
  opacity: PropTypes.string,
  buttonHref: PropTypes.string,
}

StoryCardStack.defaultProps = {
  textColor: "light-1",
  buttonColor: "light-2",
  catalog: "STANDARD",
  opacity: '70%',
  buttonHref: undefined,
  cardTaglineHeadingLevel: undefined,
  cardTaglineHorizontalAlign: undefined,
  cardTaglineVerticalAlign: undefined,
  cardTaglineTextAlign: undefined,
  cardTaglineMarginTop: "small",
  cardTaglineSecond: null,
}

export default StyledStoryCardStack