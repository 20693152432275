import React from "react"
import styled from "styled-components"
import { Box, Grid, Heading, List, Markdown, Paragraph } from "grommet"
import StyledStoryCardStack from "../components/storyCardStack"
import Link from "../components/link"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import { storiesMetadata } from "../components/data/storiesMetadata"
import ImageSharpImage from "../components/imageHandling/imageSharpImage"

/*
 *  Remember you can only have one static query per page
 *  Add new queries and tag them as below (brandMetadataMission) if needed
 *  We need to filter the front matter to get a tag line
 */
const siteDataQuery = graphql`
query {
  brandMetadata: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/company-info/"}}, sort: {fields: fileAbsolutePath, order: ASC}) {
    edges {
      node {
        id
        rawMarkdownBody
        fileAbsolutePath
        excerpt
        html
        frontmatter {
          title
          tag
        }
      }
    }
  }
}
`

const storyListPlaceholder = ["Innovation Needs an Overhaul"]
const StyledMissionPre = styled.pre`
overflow: inherit;
white-space: wrap;
text-overflow: ellipsis;
text-size: large;
width: full;
background-color: #444444;
`

const IndexPage = () => {
  const useMountEffect = (fun) => React.useEffect(fun, [])

  const getRandomInt = (min, max) => {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  // DET FIXME - the blog page title should be in site metadata
  const [blogSitePath] = React.useState("/blog/")

  const [initialChild] = React.useState(getRandomInt(0, (storiesMetadata.length - 1)))

  // follow the cards in carousel and determine if there is a link
  const [cardButtonHref, setCardButtonHref] = React.useState(undefined)

//  console.log('Initial child = ' + initialChild)

  const siteData = useStaticQuery(siteDataQuery)

  //  console.log('Site data = ' + JSON.stringify(siteData.brandMetadataMission.edges, null, 2))

  const brandMetadataTagline = siteData.brandMetadata.edges.filter(elem => elem.node.frontmatter.tag === "tagline")[0].node.rawMarkdownBody
  if (!brandMetadataTagline) {
    // gatsby build will fail so we will never accidentally deploy
    throw Error("Metadata retrieval error - cannot load tagline")
  }
  const brandMetadataHowWeHelp = siteData.brandMetadata.edges.filter(elem => elem.node.frontmatter.tag === "how-we-help")[0].node.rawMarkdownBody
  if (!brandMetadataHowWeHelp) {
    // gatsby build will fail so we will never accidentally deploy
    throw Error("Metadata retrieval error - cannot load tagline")
  }
  const brandMetadataIntroduction = siteData.brandMetadata.edges.filter(elem => elem.node.frontmatter.tag === "introduction")[0].node.rawMarkdownBody
  if (!brandMetadataIntroduction) {
    // gatsby build will fail so we will never accidentally deploy
    throw Error("Metadata retrieval error - cannot load tagline")
  }
  const brandMetadataCallToAction = siteData.brandMetadata.edges.filter(elem => elem.node.frontmatter.tag === "call-to-action")[0].node.rawMarkdownBody
  if (!brandMetadataCallToAction) {
    // gatsby build will fail so we will never accidentally deploy
    throw Error("Metadata retrieval error - cannot load tagline")
  }

  const carouselTheme = {
    carousel: {
      animation: {
        duration: 400
      },
      icons: {
        color: "blue"
      },
      disabled: {
        icons: {
          color: "grey"
        }
      }
    }
  }

  const renderStoryByTag = (storyTag) => {
    console.log('Checking for story tag = ' + storyTag)
    return (
      storiesMetadata.filter(elem => elem.tag === storyTag).map(entry => {
        console.log('Found entry for tag = ' + storyTag + ', value = ' + JSON.stringify(entry, null, 2))
          return (
            <Box
              // use a pad optionally to keep arrows away from screen
              // if you put a fill here the button will move to bottom because of stretch option in card
              fill
            >
              <StyledStoryCardStack
                cardTagLine={entry.tagline}
                cardCallToAttention={entry.actionCall}
                imageIdentifier={entry.imageIdentifier}
                catalog={entry.imageCatalog}
                textColor={entry.textColor}
                actionTextColor={entry.actionTextColor}
                taglineTextColor={entry.taglineTextColor}
                // if not buttonHref, the button will not be rendered
                // buttonHref={blogSiteTitle + "?topic=" + entry.tag}
                // blocking the button for now until we figure out how to read carousel index
                debugConsole={true}
              />
            </Box>
          )
        }
      )
    )
  }

  // the Carousel does not have a children prop so just return a set of objects
  const renderCarouselChildren = () => {
    return (
      storiesMetadata.map((entry, index) =>
        <Box
          // use a pad optionally to keep arrows away from screen
          // if you put a fill here the button will move to bottom because of stretch option in card
        >
          <StyledStoryCardStack
            cardTagLine={entry.tagline}
            cardCallToAttention={entry.actionCall}
            imageIdentifier={entry.imageIdentifier}
            catalog={entry.imageCatalog}
            textColor={entry.textColor}
            actionTextColor={entry.actionTextColor}
            taglineTextColor={entry.taglineTextColor}
            // if not buttonHref, the button will not be rendered
            // buttonHref={blogSiteTitle + "?topic=" + entry.tag}
            // blocking the button for now until we figure out how to read carousel index
            debugConsole={false}
          />
        </Box>
      )
    )
  }

  const onFocusCarousel = (value) => {
    console.log("The active index is " + value.activeIndex)
  }

  const onActiveSlideChange = (value) => {
//    console.log('On focus The value = ' + JSON.stringify(value, null, 2))
//    console.log('Location details = ' + value.view.document.location)
//    console.log('Location details = ' + value.view.window.clientInformation)
    // new active index so check out whether the ref is there and update
    // FIXME - add specific state bool to metadata - for now a negative index would mean "not used" so we'd skip
    const matchingEntry = storiesMetadata.find(elem => elem.defaultStoryIndex === value.activeIndex)
    const newHref =
      (matchingEntry && matchingEntry.length > 0) ? blogSitePath + "?topic=" + matchingEntry[0].tagline : undefined
    console.log("For new active index " + value.activeIndex + " the tag line = " + newHref)
    setCardButtonHref(newHref)
  }

  const onBlurCarousel = (value) => {
    // happens when going somewhere else
    console.log("On blur The value = " + value)
  }

  /*
   *  First pass of emulating a button click in image area
   *  FIXME - figure out the exact location of button element and only allow click inside it
   */
  const onClickCarousel = (value) => {
    // click anywhere
    value.preventDefault()
    let currentTargetRect = value.currentTarget.getBoundingClientRect()
    const mouseOffsetX = value.clientX - currentTargetRect.left,
      mouseOffsetY = value.clientY - currentTargetRect.top

    const mouseRelativeX = mouseOffsetX / currentTargetRect.width,
      mouseRelativeY = mouseOffsetY / currentTargetRect.height

    console.log("On click The value = " + Object.keys(value))
//    console.log('Client X = ' + value.clientX + ', client Y = ' + value.clientY)
//    console.log('Page X = ' + value.pageX + ', page Y = ' + value.pageY)
//    console.log('Bounding rect = ' + currentTargetRect.width + ', ' + currentTargetRect.height)
//    console.log('Page Offset X = ' + value.pageXOffset + ', page Y = ' + value.pageYOffset)

//    console.log('Mouse offset X/Y = ' + mouseOffsetX + ', ' + mouseOffsetY)
    console.log("Mouse relative X/Y = " + mouseRelativeX + ", " + mouseRelativeY)
    console.log("The new active index is " + value.view.window.activeIndex)

    if (0.8 < mouseRelativeX && 0.85 < mouseRelativeY && mouseRelativeY < 0.95) {
      const matchingEntry = storiesMetadata.find(elem => elem.defaultStoryIndex === value.activeIndex)
      const newHref =
        (matchingEntry && matchingEntry.length > 0) ? blogSitePath + "?topic=" + matchingEntry[0].tagline : undefined
      console.log("For new active index " + value.activeIndex + " the tag line = " + newHref)
      console.log("Time to navigate to " + newHref)
    }
//    console.log('Screen X = ' + value.screenX + ', screen Y = ' + value.screenY)
//    console.log('Button = ' + value.button + ', type = ' + value.type)
//    console.log('Target = ' + Object.keys(value.target))
//    console.log('View = ' + Object.keys(value.view))
//    console.log('View details = ' + value.view.innerHeight + ', ' + value.view.innerWidth)
//    console.log('View width and length = ' + value.view.width + ', ' + value.view.length)
//    console.log('View page offset = ' + value.view.pageXOffset + ', ' + value.view.pageYOffset)
//    console.log('Screen left/top = ' + value.view.screenLeft + ', ' + value.view.screenTop)
  }

  /*
   *  OUTER BOX NOTES
   *    Remember to set a height prop (not min - actual) so resizing won't make the carousel go away
   *  CAROUSEL NOTES
   *    Not yet sure of syntax to specify arrows and selector in controls - default is both anyway
   *  STYLED CARD NOTES
   */
  return (
    <Layout>
      <SEO title="3 Leaps Home Page - We help highly transactional businesses to automate, speed and scale decision-making"/>
      <Box
        background={"light-2"}
        direction={"column"}
        // if memory serves this places min width on resize browser window, at least in chrome
        // but maybe not since xsmall ends up as same size - check heading in middle part of grid as constraint or header
        //basis={"full"}
        fill
      >
        <Box
          direction={"column"}
          alignContent={"center"}
          // this will stretch the carousel - blocking fill and basis will keep aspect ratio
          fill={true}
          height={"small"}
          //basis={"full"}
          //border={{color: "red"}}
          // setting max will prevent excessive distortion in image aspect ratio
          // mind the overall height of grid component though
          // it will not minimize resizing though
          // ALSO NOTE CAROUSEL DISABLED SINCE WE DON'T HAVE NAVIGATION CARDS
          // in firefox if you put max constraint it does work - chrome doesn't always - just use min to fill
          width={{ min: "small" }}
          background={"brand"}
        >
        <Grid
          // making row large adds whitespace vertically but doesn't change image - something is stretching horizontally
          // use fit for first row and then we should be able to sort out the image box
          rows={["flex", "auto", "auto"]}
          columns={["flex"]}
          //        fill={'vertical'}
          // use fill = horizontal to block extra space above the next section
          // but the controls bleed into picture a bit
          fill={"horizontal"}
          align={"stretch"}
          // alignSelf is in cross axis when in a box
          alignSelf={"stretch"}
          gap={"none"}
          // this will center the content if you use justify=center on horizontal
          justify={"stretch"}
          /*
          areas={[
            { name: "hero", start: [0, 0], end: [0, 0] },
            { name: "intro", start: [0, 1], end: [0, 1] },
            { name: "stories", start: [0, 2], end: [0, 2] }
          ]}
          */
          areas={[
            { name: "hero", start: [0, 0], end: [0, 0] },
            { name: "intro", start: [0, 1], end: [0, 1] },
            { name: "stories", start: [0, 2], end: [0, 2] }
          ]}
        >
          <Box
            gridArea={"hero"}
            direction={"column"}
            alignContent={"center"}
            // this will stretch the carousel - blocking fill and basis will keep aspect ratio
            fill={true}
            //basis={"full"}
            //border={{color: "red"}}
            // setting max will prevent excessive distortion in image aspect ratio
            // mind the overall height of grid component though
            // it will not minimize resizing though
            // ALSO NOTE CAROUSEL DISABLED SINCE WE DON'T HAVE NAVIGATION CARDS
            // in firefox if you put max constraint it does work - chrome doesn't always - just use min to fill
            //width={{ min: "small" }}
            height={"medium"}
            background={"light-2"}
          >
            <Box
              // this will stretch the carousel - blocking fill and basis will keep aspect ratio
              //basis={"full"}
              //border={{color: "red"}}
              // with basis autom above, align self will move in row axis
              fill={true}
              alignSelf={"center"}
              justify={"evenly"}
              //margin={"medium"}
              /*
               *  The images will not render unless you set the width to an actual constraint
               *  We are using fluid images so they need boundaries
              width={"xlarge"}
               */
              //        height={"medium"}
              //fill={"vertical"}
              // setting maxi will prevent excessive distortion in image aspect ratio
              // mind the overall height of grid component though
              // it will not minimize resizing though
              // ALSO NOTE CAROUSEL DISABLED SINCE WE DON'T HAVE NAVIGATION CARDS
              //background={"red"}
            >
              <ImageSharpImage
                imageIdentifier={"CARD-HERO-MAIN-TAGLINE"}
              />
            </Box>
          </Box>
          <Box
            gridArea={"intro"}
            fill={true}
            background={"light-2"}
//            pad={{ "vertical": "small" }}
          >
            <Box
//              pad={{ "vertical": "xsmall" }}
//                          border={{side: "horizontal", size: "small", style: "groove"}}
            >
              <Heading
                alignSelf={"center"}
                level={2}
                textAlign={"center"}
                margin={{ horizontal: "small", vertical: "small" }}
              >
                Decisions Optimized<br/>Strategies Realized
              </Heading>
            </Box>
            <Box
//              pad={{ "top": "small" }}
              margin={{ "horizontal": "small" }}
            >
              <Box
                margin={{ "horizontal": "large" }}
                //              border={{side: "all", size: "small", style: "solid"}}
//                pad={{ vertical: "small" }}
                round={"medium"}
              >
                <Markdown
                  components={{ p: (props) => <Paragraph {...props} fill/> }}
                >
                  {brandMetadataCallToAction}
                </Markdown>
              </Box>
            </Box>
          </Box>
          <Box
            gridArea={"stories"} background={"neutral-1"}
            //          pad={{"vertical": "small"}}
            fill={true}
          >
            <List data={storiesMetadata.filter(elem => elem.active === true)} pad={"medium"}>
              {(datum, index) => (
                <Box
                  key={index}
                  direction="row-responsive"
                  gap="large"
                  // this moves horizontally so start is left and end is right
                  alignSelf={"start"}
                  // you can center the image and the heading IF you don't use alignSelf in box below
                  align={"center"}
                  // for this example, justify moves horizontally so start is left justify
                  // but alignSelf overrides
                >
                  <Box
                    fill={false}
                    // using alignSelf=stretch will literally stretch the image
                    // beware of using align = start and possibly moving out image
                    // as implemented don't use align other than stretch
                    align={"stretch"}
                    height={{ max: "medium" }}
                    width={{ min: "xsmall" }}
                  >
                    <ImageSharpImage
                      imageIdentifier={datum.imageIdentifier}
                    />
                  </Box>
                  <Link to={blogSitePath + "?topic=" + datum.tag}>
                    <Heading level={3}>{datum.tagline}</Heading>
                  </Link>
                </Box>
              )}
            </List>
          </Box>
        </Grid>
      </Box>
      </Box>
    </Layout>
  )
}

export default IndexPage
